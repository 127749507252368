import React, { ChangeEvent, useState } from "react";
import { Button, message, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLevel1Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel1Onboarding";
import { qualifications } from "../qualifications";
import { useGetSubjectsQuery } from "../../../../hooks/api/Admin/Subjects/useGetSubjectsQuery";
import { useUploadFile } from "../../../../hooks/misc/useUploadFile";
import axios from "axios";
import { getToken } from "../../../../utils/getToken";

const LevelOne = () => {
  const [payload, setPayload] = useState({
    professionalTitle: "",
    profilePicture: "",
    areaOfExpertise: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setPayload({ ...payload, [name]: value });
  };

  const { mutateAsync } = useLevel1Onboarding();
  const page = 1;
  const { data } = useGetSubjectsQuery({ page });

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, profilePicture: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const handleSubmit = () => {
    mutateAsync(payload);
  };
  const token = getToken();

  return (
    <div>
      <div className="w-full mt-4">
        {payload?.profilePicture ? (
          <button
            onClick={() => setIsModalVisible(true)}
            className="flex flex-col gap-2 items-center"
          >
            <img
              src={payload?.profilePicture}
              alt="Receipt"
              style={{ height: "140px" }}
            />
            <button
              className="mt-3 bg-primary800 p-2 rounded-md text-white"
              onClick={() => setIsModalVisible(true)}
            >
              Preview Image
            </button>
          </button>
        ) : (
          <input
            type="file"
            multiple={false}
            onChange={handleFileChange}
            className="mb-4"
          />
        )}
        <Modal
          title="Image Preview"
          open={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
        >
          <img
            src={payload?.profilePicture}
            alt="avatar preview"
            style={{ width: "100%" }}
          />
        </Modal>

        <label htmlFor="professionalTitle" className="text-gray700 block">
          Professional title
        </label>
        <select
          name="professionalTitle"
          onChange={handleChange}
          value={payload?.professionalTitle}
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        >
          <option value="" disabled>
            Select Option
          </option>
          {qualifications &&
            qualifications.map(({ degree, professional_title }) => (
              <option value={degree} key={degree}>
                {professional_title}
              </option>
            ))}
        </select>
      </div>
      <div className="w-full my-4">
        <label htmlFor="areaOfExpertise" className="text-gray700">
          Area of expertise
        </label>
        {/* <input
          name="areaOfExpertise"
          type="text"
          value={payload.areaOfExpertise}
          onChange={handleChange}
          placeholder="Select an option"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        /> */}
        <select
          name="areaOfExpertise"
          onChange={handleChange}
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none capitalize"
        >
          <option value="" disabled>
            Select option
          </option>
          {data?.data?.docs &&
            data?.data?.docs?.map(({ subject }: { subject: string }) => (
              <option value={subject} className="capitalize">
                {subject}
              </option>
            ))}
        </select>
      </div>
      <button
        disabled={
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
        }
        className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md float-right ${
          !payload?.areaOfExpertise ||
          !payload?.professionalTitle ||
          !payload?.profilePicture
            ? "opacity-50"
            : ""
        }`}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
};

export default LevelOne;
