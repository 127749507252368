import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  title: string;
  to?: string;
  loading: boolean;
};

const SecondaryButton = ({ title, to, loading }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium ${
        loading ? "opacity-50" : ""
      }`}
      disabled={loading}
    >
      {loading ? <LoadingOutlined /> : title}
    </button>
  );
};

export default SecondaryButton;
