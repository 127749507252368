/* eslint-disable react/display-name */
import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTutorQuery } from "../hooks/api/Tutors/Auth/useGetTutorQuery";

const withTutorAuth = (WrappedComponent) => {
  const HOC = (props) => {
    const navigate = useNavigate();
    const stringifyToken = localStorage.getItem("W2ST_TUTOR_TOKEN");
    const { data: tutor } = useGetTutorQuery();

    useEffect(() => {
      if (!stringifyToken) {
        navigate("/");
        message.error("Unauthorized, proceed to sign in");
      } else if (tutor?.data?.onBoardingProgress < 3) {
        navigate("/tutor/onboarding", { replace: true });
      }
    }, [navigate, stringifyToken, tutor]);

    return <WrappedComponent {...props} />;
  };

  // Set a display name for better debugging
  HOC.displayName = `WithTutorAuth(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return HOC;
};

export default withTutorAuth;
