import React, { ReactNode, useEffect } from "react";
import Navbar from "./Navbar";
import { Link, useLocation } from "react-router-dom";

const Layout = ({ children }: { children: ReactNode }) => {
  const Facebook = require("../../../assets/socials/fb.png");
  const LinkedIn = require("../../../assets/socials/lk.png");
  const Twitter = require("../../../assets/socials/tw.png");
  const Instagram = require("../../../assets/socials/ig.png");
  const logo = require("../../../assets/images/logo-white.png");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Navbar />
      <div>{children}</div>
      <footer className="bg-primary900 p-10 md:p-[64px]">
        <section>
          <Link to="/" className="cursor-pointer mb-[24px]">
            <img
              src={logo}
              className="h-[35px]"
              alt="Logo of Way2SuccessTutors"
            />
          </Link>
        </section>
        <section className="md:flex gap-[48px] text-white mt-5 border-b-[1px] pb-5 md:pb-10 mb-[64px] text-tmd">
          <Link className="W2STLight block mb-4 md:mb-0 md:inline" to="/">
            For Students
          </Link>
          <Link className="W2STLight block mb-4 md:mb-0 md:inline" to="/tutors">
            For Tutors
          </Link>
          <Link className="W2STLight block mb-4 md:mb-0 md:inline" to="/about">
            About Us
          </Link>
          <Link className="W2STLight block mb-4 md:mb-0 md:inline" to="/#faq">
            FAQs
          </Link>
        </section>
        <section className="mt-[10px] md:mt-[31px] block md:flex justify-between items-center">
          <section className=" flex gap-4">
            <Link to="">
              <img src={Facebook} className="h-[30px] w-[30px]" alt="" />
            </Link>
            <Link to="">
              <img src={Twitter} className="h-[30px] w-[30px]" alt="" />
            </Link>
            <Link to="">
              <img src={Instagram} className="h-[30px] w-[30px]" alt="" />
            </Link>
            <Link to="">
              <img src={LinkedIn} className="h-[30px] w-[30px]" alt="" />
            </Link>
          </section>
          <section className="block mt-10 md:flex gap-4 text-gray25 text-tsm">
            <Link to="/terms" className="">
              Terms of Service
            </Link>
            <Link to="/policy" className="ml-20 md:ml-0">
              Privacy Policy
            </Link>
          </section>
        </section>
      </footer>
      <div className="footer-pattern" />
    </div>
  );
};

export default Layout;

// import React, { ReactNode, useEffect } from "react";
// import Navbar from "./Navbar";
// import { Link, useLocation } from "react-router-dom";

// const Layout = ({ children }: { children: ReactNode }) => {
// const location = useLocation();
//   const Facebook = require("../../../assets/socials/fb.png");
//   const LinkedIn = require("../../../assets/socials/lk.png");
//   const Twitter = require("../../../assets/socials/tw.png");
//   const Instagram = require("../../../assets/socials/ig.png");
//   const logo = require("../../../assets/images/logo-white.png");

//   // Rest of your component remains the same
//   return (
//     <div>
//       <Navbar />
//       <div>{children}</div>
//       {/* ... rest of your JSX ... */}
//     </div>
//   );
// };

// export default Layout;
