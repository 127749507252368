import React from "react";

const Loader = () => {
  return (
    <div className=" w-full z-50 flex justify-center items-center">
      <div className="my-[100px]">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loader;
