import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";

export const useGetSubjectsQuery = ({ page }: { page: number }) => {
  // const token = localStorage.getItem("W2ST_ADMIN_TOKEN");

  return useQuery({
    queryKey: ["useGetSubjectsQuery", page],
    queryFn: async () => {
      // if (!token) throw new Error("Authorization token is missing");

      const response = await axiosInstance.get("tutors/subjects", {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
        params: {
          page,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      console.log("Success Getting Tutors ==>", data);
    },
    onError: (error: unknown) => {
      console.error("Failed Getting Tutors ==>", error);
    },
    // enabled: !!token,
  });
};
