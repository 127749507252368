import React from "react";
import Layout from "./Public/Components/Layout";

const TermsAndConditions = () => {
  return (
    <Layout>
      <section className="container mx-auto py-20 px-4 md:px-10 lg:px-20">
        <h1 className="text-3xl font-bold mb-10">Terms and Conditions</h1>

        <div className="space-y-8">
          {/* Introduction */}
          <div>
            <h2 className="text-2xl font-semibold">Introduction</h2>
            <p className="text-gray-700 mt-4">
              These Terms of Use control your use of the Way2SuccessTutors
              website on desktop, mobile, or any other digital device. Kindly
              read these Terms and Conditions before using or continuing to use
              our online tutoring platform. By registering for our services or
              continuing to use the website, you agree and accept all terms and
              conditions stated on this page.
            </p>
            <p className="text-gray-700 mt-4">
              Please note that a user may register for our services on this site
              as a tutor or student. The user has the right to browse the
              website without registration. However, if the user does sign-up
              for our services, we have the right to request and store certain
              information for optimal user experience. Registering to the
              website means that you understand and accept this condition.
            </p>
          </div>

          {/* Section 1: Rights and Responsibilities of Way2SuccessTutors */}
          <div>
            <h2 className="text-2xl font-semibold">
              1. Rights and Responsibilities of Way2SuccessTutors
            </h2>
            <p className="text-gray-700 mt-4">
              This clause states the rights and obligations of our company.
            </p>
            <ul className="list-decimal list-inside text-gray-700 mt-4 space-y-2">
              <li>
                To provide tutors with advertising space. Selected tutors can
                use our online platform to communicate with students and find
                tutoring jobs. The website can be used for interaction and
                scheduling private tutoring sessions.
              </li>
              <li>
                To get our website updated and upgraded regularly for optimal
                functionality.
              </li>
              <li>
                We do not guarantee that the website will be available 24x7.
                Maintenance issues may arise, and we will notify users of any
                downtime.
              </li>
              <li>
                To ensure that the website is safe and secure from cyberattacks,
                viruses, and malicious malware.
              </li>
              <li>
                To send notifications regarding booking confirmations and
                cancellations via email to both tutors and students.
              </li>
              <li>
                To provide seamless and secure payment channels for bookings and
                refunds.
              </li>
              <li>
                To monitor chats and postings for abuse, taking swift action
                against unlawful conduct.
              </li>
            </ul>
          </div>

          {/* Section 2: Rights and Responsibilities of All Users */}
          <div>
            <h2 className="text-2xl font-semibold">
              2. Rights and Responsibilities of All Users
            </h2>
            <ul className="list-decimal list-inside text-gray-700 mt-4 space-y-2">
              <li>
                Users are responsible for the content they post online,
                including messages and files shared during sessions. Offensive
                or unlawful material is prohibited.
              </li>
              <li>
                Users must notify customer support of any offensive or illegal
                content shared by others.
              </li>
              <li>
                Contact support if there are connectivity issues or if your
                account is compromised.
              </li>
              <li>
                The platform is strictly for tutoring services—no selling or
                advertising other products.
              </li>
              <li>All users agree to comply with the privacy policy.</li>
            </ul>
          </div>

          {/* Section 3: Rights and Responsibilities of Tutors */}
          <div>
            <h2 className="text-2xl font-semibold">
              3. Rights and Responsibilities of Tutors
            </h2>
            <ul className="list-decimal list-inside text-gray-700 mt-4 space-y-2">
              <li>
                Tutors must provide verified documents (e.g., transcripts,
                certificates) when applying.
              </li>
              <li>
                Updated contact information and personal details are required
                from tutors.
              </li>
              <li>
                Tutors must confirm or cancel a session within 12 hours of its
                scheduling.
              </li>
              <li>
                Attendance is crucial; sessions should start and end within the
                designated timeframe.
              </li>
              <li>
                Maintain proper equipment and good internet connectivity for
                online classes.
              </li>
              <li>
                For in-person classes, tutors should ensure they can travel to
                the location.
              </li>
              <li>
                Tutors can cancel or postpone a session with at least 12-24
                hours notice, without penalties.
              </li>
              <li>
                Tutors can report misbehaving students or disruptive parents,
                and the platform will assess and address the situation.
              </li>
            </ul>
          </div>

          {/* Section 4: Payment */}
          <div>
            <h2 className="text-2xl font-semibold">4. Payment</h2>
            <p className="text-gray-700 mt-4">
              We act as a middleman between students and tutors. All
              transactions are conducted through our platform. Tutors must
              maintain valid account details for smooth financial transactions.
              Payments should not be made in cash or via direct checks.
            </p>
            <p className="text-gray-700 mt-4">
              Cancellation penalties may apply if tutors fail to inform us of
              schedule changes within the required time.
            </p>
          </div>

          {/* Section 5: Code of Conduct */}
          <div>
            <h2 className="text-2xl font-semibold">5. Code of Conduct</h2>
            <p className="text-gray-700 mt-4">
              Tutors and students must follow the platform’s rules and conduct
              themselves in a professional and lawful manner at all times.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsAndConditions;
