import React, { useState } from "react";
import { useLevel2Onboarding } from "../../../../hooks/api/Tutors/Onboarding/useLevel2Onboarding";

const LevelTwo = () => {
  const [payload, setPayload] = useState({
    preferredLanguage: "English",
    country: "United States of America",
    hourlyRate: "",
    profileLinks: {
      facebook: "",
      linkedIn: "",
      x: "",
      personalWebsite: "",
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Handle nested updates for profileLinks
    if (["facebook", "linkedIn", "x", "personalWebsite"].includes(name)) {
      setPayload((prev) => ({
        ...prev,
        profileLinks: {
          ...prev.profileLinks,
          [name]: value,
        },
      }));
    } else if (name === "hourlyRate") {
      // Ensure hourly rate does not exceed 200
      const validatedRate = Math.min(Number(value), 200);
      setPayload((prev) => ({
        ...prev,
        hourlyRate: validatedRate.toString(),
      }));
    } else {
      setPayload((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const { mutateAsync } = useLevel2Onboarding();

  const handleSubmit = () => {
    mutateAsync(payload);
  };

  const isSubmitDisabled =
    !payload.country || !payload.hourlyRate || !payload.preferredLanguage;

  return (
    <div>
      <div className="w-full my-4">
        {/* Hourly Rate */}
        <label
          htmlFor="hourlyRate"
          className="text-gray700 W2STMedium mt-4 block"
        >
          Hourly Rate
        </label>
        <select
          name="hourlyRate"
          onChange={handleChange}
          value={payload?.hourlyRate}
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        >
          <option value="" disabled>
            Select Option
          </option>
          {Array.from({ length: 7 }, (_, index) => 5 * (index + 1)).map(
            (value) => (
              <option value={value} key={value}>
                ${value}
              </option>
            )
          )}
        </select>

        {/* Profile Links */}
        <label className="text-gray700 W2STMedium mt-4 block">
          Profile Links
        </label>
        <p className="text-gray500">
          kindly note that the provided social networks would only be used for
          background checks.
        </p>
        <input
          name="facebook"
          type="url"
          value={payload.profileLinks.facebook}
          onChange={handleChange}
          placeholder="https://facebook.com/username"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        />
        <input
          name="linkedIn"
          type="url"
          value={payload.profileLinks.linkedIn}
          onChange={handleChange}
          placeholder="https://linkedin.com/in/username"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        />
        <input
          name="x"
          type="url"
          value={payload.profileLinks.x}
          onChange={handleChange}
          placeholder="https://x.com/username"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        />
        <input
          name="personalWebsite"
          type="url"
          value={payload.profileLinks.personalWebsite}
          onChange={handleChange}
          placeholder="https://example.com"
          className="w-full border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none"
        />

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          className={`mt-8 w-full py-3 mb-4 bg-primary900 rounded-md text-white text-md ${
            isSubmitDisabled ? "opacity-50" : ""
          }`}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LevelTwo;
