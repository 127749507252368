import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetBookingsAdmin } from "../../../hooks/api/Admin/Bookings/useGetBookingsAdmin";
import { useGetTutorsonAdminQuery } from "../../../hooks/api/Admin/Tutors/useGetTutorsonAdminQuery";
import Loader from "../../../components/Loader";

const AdminBookings = () => {
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { data } = useGetBookingsAdmin(id);

  const { data: tutors, isLoading } = useGetTutorsonAdminQuery({
    search: search,
    page: page,
  });

  return (
    <AdminDashboardLayout>
      {isLoading && <Loader />}
      <div className="mx-auto max-w-[500px] rounded-lg bg-white p-[25px] shadow-sm">
        <div className="heading">Select Tutor</div>
        <select
          name="subject"
          // value={formData.subject}
          // onChange={handleChange}
          className="border-[1px] rounded-md border-gray300 p-4 block mt-3 outline-none w-full"
          required
        >
          <option value="" disabled>
            Select a subject
          </option>
          {/* {tutor?.data?.areaOfExpertise?.length > 0 ? (
            tutor.data.areaOfExpertise.map((subject: string) => (
              <option key={subject} value={subject}>
                {subject}
              </option>
            ))
          ) : (
            <option disabled>No subjects available</option>
          )} */}
        </select>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
        similique porro consequatur vero saepe iusto iste minus quaerat suscipit
        harum eveniet numquam, alias eligendi voluptatibus, facere illo dolore
        quam cupiditate.
      </div>
      {/* <p>{JSON.stringify(tutors?.data?.docs)}</p> */}
    </AdminDashboardLayout>
  );
};

export default AdminBookings;
