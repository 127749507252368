import { useMutation } from "react-query";
import { axiosInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";

type Props = {
  tutorId: string;
  subject: string;
  tutorShipType: string;
  date: string;
  startTime: string;
  endTime: string;
  notes: string;
};

export const useCreatePaymentIntentMutation = () => {
  const token = localStorage.getItem("W2ST_STUDENT_TOKEN");

  return useMutation(
    (payload: Props) =>
      axiosInstance
        .post("bookings/checkout", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: async (data: any) => {
        message.success("Payment Initiated");
        if (data?.data) {
          window.open(data.data, "_blank");
        }
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.message || "Failed... Please try again."
        );
      },
    }
  );
};
