import React, { useState } from "react";
import { LuAward, LuCalendar, LuClock4 } from "react-icons/lu";
import { FaRegStar } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";
import StudentDashboard from "../../../layout/StudentDashboard";
import { Link } from "react-router-dom";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import { useGetStudentQuery } from "../../../hooks/api/Students/Auth/useGetStudentQuery";
import AvailabilityBadge from "../Tutors/Components/AvailabilityBadge";
import { FiBriefcase } from "react-icons/fi";
import Loader from "../../../components/Loader";
import { Drawer } from "antd";
import { GrClose } from "react-icons/gr";
import { useGetTutorQuery } from "../../../hooks/api/Students/Tutors/useGetTutorQuery";
import TutorBookingForm from "../Tutors/Components/TutorBookingForm";

const StudentOverview = () => {
  const { data: student } = useGetStudentQuery();
  const { data: tutors, isLoading } = useGetTutorsQuery({});
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);

  const { data: tutorsData, isLoading: tutorsLoading } = useGetTutorQuery(id);

  return (
    <StudentDashboard>
      <div className="container px-6">
        <p className="text-txs text-gray900 W2STBold md:mt-0 mt-3">
          Welcome{student?.data?.firstName && ", " + student?.data?.firstName}
        </p>
        <div className="pb-6 border-b-[1px] border-b-gray300 text-tmd">
          <span className="text-gray600">No upcoming sessions, </span>
          <Link
            to="/student/tutors"
            className="text-primary500 underline underline-offset-2 cursor-pointer"
          >
            let's find you a tutor
          </Link>
        </div>
        <div className="md:flex grid gap-4 md:mt-10 mt-3 mb-10">
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-primary600 mb-2">
              <span className="rounded-full bg-primary50 p-4 text-[30px]">
                <LuCalendar />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Sessions booked
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">20</p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-bluelight600 mb-2">
              <span className="rounded-full bg-bluelight50 p-4 text-[30px]">
                <LuClock4 />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Total session time
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">1,210 Mins</p>
          </div>
          <div className="rounded-lg p-3 border-[1px] bordeer-gray100  md:w-4/12 w-full bg-white">
            <div className="flex gap-4 items-center text-warning600 mb-2">
              <span className="rounded-full bg-warning50 p-4 text-[30px]">
                <FaRegStar />
              </span>
              <span className="text-gray900 W2STMedium text-tmd">
                Average rating
              </span>
            </div>
            <p className="text-dmd text-gray900 W2STBold ml-2">4.5/5</p>
          </div>
        </div>
        <p className="text-txl text-gray900 W2STBold">Suggested Tutors</p>
        <div className="pb-6 border-b-[1px] border-b-gray300 flex justify-between">
          <div>
            <span className="text-gray600">
              The suggestions are based on your location{" "}
            </span>
          </div>
          <Link
            to="/student/tutors"
            className="flex gap-3 text-gray500 items-center"
          >
            See more <BsArrowRightShort />
          </Link>
        </div>
        <div className="mb-[100px]">
          {isLoading && <Loader />}
          <div className="md:grid md:grid-cols-3 gap-3 mt-5">
            {tutors &&
              tutors?.data?.docs.length > 0 &&
              !isLoading &&
              tutors?.data?.docs
                .slice(0, 4)
                .map(
                  ({
                    firstName,
                    lastName,
                    professionalTitle,
                    availabilityType,
                    id,
                    hourlyRate,
                  }: any) => (
                    <div
                      key={id}
                      className="bg-white rounded-md shadow-sm overflow-hidden cursor-pointer"
                      onClick={() => {
                        setId(id);
                        setVisible(true);
                      }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc22FgIapuN5SmPjiN6uI3Vfj1uNO1ShdSg&s"
                        alt="tutor avatar"
                        className="h-[218px] rounded-t-lg w-full"
                      />
                      <div className="p-[16px]">
                        <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                          {firstName + " " + lastName}
                        </h3>
                        <div className="mt-2">
                          <div className="flex gap-3 items-center">
                            <FiBriefcase style={{ color: "text-gray700" }} />
                            <span className="text-gray500 text-sm">
                              {professionalTitle}
                            </span>
                          </div>
                        </div>

                        <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                          <LuAward />
                          <span className="text-gray500">
                            15 sessions (5 reviews)
                          </span>
                        </div>
                        <AvailabilityBadge
                          availabilityType={availabilityType}
                        />
                        <div className="flex justify-between mt-[16px]">
                          <div>
                            <p className="W2STMedium text-gray900">
                              ${hourlyRate}/hr
                            </p>
                            <h3 className="text-gray500">Hourly rate</h3>
                          </div>
                          <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                            Book Tutor
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
          </div>
          <Drawer
            title={
              <div className="flex justify-between items-center">
                <h3>Tutor Details</h3>
                <GrClose
                  onClick={() => setVisible(false)}
                  className="cursor-pointer"
                />
              </div>
            }
            open={visible}
            closable={false}
            maskClosable={false}
          >
            {tutorsLoading && <Loader />}
            {!tutorsLoading && tutorsData && (
              <div>
                <div className="flex gap-2 mb-3">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc22FgIapuN5SmPjiN6uI3Vfj1uNO1ShdSg&s"
                    className="h-[60px] rounded-full w-[60px]"
                    alt="avatar"
                  />
                  <div className="flex-1">
                    <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                      {tutorsData?.data?.firstName +
                        " " +
                        tutorsData?.data?.lastName}
                    </h3>
                    <div className="mt-2">
                      <div className="flex gap-3 items-center">
                        <FiBriefcase style={{ color: "text-gray700" }} />
                        <span className="text-gray500">
                          {tutorsData?.data?.professionalTitle}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TutorBookingForm id={id} setVisible={setVisible} />
                </div>
              </div>
            )}
          </Drawer>
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentOverview;
