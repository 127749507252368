import React, { ReactNode } from "react";
import { GrNotification } from "react-icons/gr";
import {
  BiBriefcaseAlt,
  BiBookOpen,
  BiCalendarEvent,
  BiTime,
} from "react-icons/bi";
import { CgSupport } from "react-icons/cg";
import { AiOutlineUser } from "react-icons/ai";
import { FiLayers, FiSettings, FiLogOut, FiUsers } from "react-icons/fi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withAdminAuth from "../hoc/withAdminAuth";

const AdminDashboardLayout = ({
  children,
  right,
}: {
  children: ReactNode;
  right?: any;
}) => {
  const Logo = require("../assets/images/logo.png");
  const Avatar = require("../assets/images/avatar.png");
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  return (
    <div>
      <div style={{ height: "100vh", overflowY: "hidden" }}>
        <div className="flex justify-between items-center border-b-2 p-4 bg-white">
          <div className="flex gap-4 items-center">
            <img src={Logo} alt="W2ST Logo" className="h-[40px]" />
            <div>
              <span className="text-purple900 font-bold text-xs bg-primary200 p-1 rounded-md rotate-[20deg] shadow-md">
                ADMIN
              </span>
            </div>
          </div>
          <section className="flex gap-4 items-center">
            {right}
            <GrNotification style={{ fontSize: "20px" }} />
            <img
              src={Avatar}
              alt="Admin Avatar"
              className="w-10 h-10 rounded-full bg-gray-400"
            />
          </section>
        </div>

        {/* Layout Body */}
        <div className="flex">
          {/* Sidebar */}
          <div className="w-2/12 border-r-2 h-screen bg-white p-4">
            <section className="h-[80%] relative mt-12">
              <nav>
                <ul>
                  <LayoutSideLink to="/admin/overview" title="Overview">
                    <FiLayers style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/admin/tutors" title="Tutors">
                    <AiOutlineUser style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/admin/students" title="Students">
                    <FiUsers style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/admin/bookings" title="Bookings">
                    <BiTime style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/admin/payments" title="Payments">
                    <BiBriefcaseAlt style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/admin/subjects" title="Subjects">
                    <BiBookOpen style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                </ul>
              </nav>
              <span className="absolute bottom-0">
                <nav>
                  <ul>
                    <LayoutSideLink to="/admin/support" title="Support">
                      <CgSupport style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <LayoutSideLink to="/admin/settings" title="Settings">
                      <FiSettings style={{ fontSize: "20px" }} />
                    </LayoutSideLink>
                    <li
                      className="flex items-center gap-3 text-error500 font-bold cursor-pointer"
                      onClick={logout}
                    >
                      <FiLogOut style={{ fontSize: "20px" }} />
                      <span>Logout</span>
                    </li>
                  </ul>
                </nav>
              </span>
            </section>
          </div>

          {/* Main Content */}
          <div className="w-10/12 p-4 bg-[#F9FAFB] h-screen">
            <div className="h-[90vh]" style={{ overflowY: "scroll" }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdminAuth(AdminDashboardLayout);

const LayoutSideLink = ({
  to,
  children,
  title,
}: {
  to: string;
  children: ReactNode;
  title: string;
}) => {
  const { pathname } = useLocation();

  const isActive = pathname.includes(to);

  return (
    <li className="my-3">
      <Link
        to={to}
        className={`flex gap-2 items-center W2STMedium p-2 rounded-md ${
          isActive ? "text-primary700 bg-primary50" : "text-gray500 bg-white"
        }`}
      >
        {children}
        <span className={isActive ? "text-primary700" : "text-gray700"}>
          {title}
        </span>
      </Link>
    </li>
  );
};
