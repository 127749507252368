import React, { useState } from "react";
import AdminDashboardLayout from "../../../layout/AdminDashboardLayout";
import { useGetSingleTutorsonAdmin } from "../../../hooks/api/Admin/Tutors/useGetSingleTutoronAdmin";
import { useParams } from "react-router-dom";
import Back from "../../../components/Back";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import Card from "../../../components/Card";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { LuAward } from "react-icons/lu";
import { FiBriefcase } from "react-icons/fi";
import ProfileCard from "./components/ProfileCard";
import Options from "./components/Options";
import EducationCard from "./components/EducationCard";
import ExperienceCard from "./components/ExperienceCard";
import { useVerifyTutoronAdmin } from "../../../hooks/api/Admin/Tutors/useVerifyTutoronAdmin";
import Loader from "../../../components/Loader";
import { Empty } from "antd";

const TutorDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleTutorsonAdmin(`${id}`);
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { mutateAsync } = useVerifyTutoronAdmin(`${id}`);

  const handleVerification = () => {
    mutateAsync(data, {
      onSuccess: () => {
        setOpen(false);
      },
      onError: (error) => {
        console.error("Verification failed:", error);
      },
    });
  };

  return (
    <AdminDashboardLayout>
      <Back />
      <section className="">
        <div className="flex items-center justify-between">
          <h1 className="text-xl W2STBold text-gray900">Tutor Profile</h1>
          <button className="py-2 px-6 rounded-md bg-green-500 text-white">
            Verify
          </button>
        </div>
        {/* <p>{JSON.stringify(data?.data)}</p> */}
        {/* <Modal open={open} width={400} footer={null} closeIcon={null}>
          <h2 className="text-xl mb-2 font-bold">Verify Tutor</h2>
          <p className="mb-3">Confirm if you would like to verify this tutor</p>
          <div className="flex gap-2">
            <button
              className="p-2 rounded-md border-[#B42318] border w-6/12 text-[#B42318]"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
            <button
              onClick={() => handleVerification()}
              className="p-2 rounded-md bg-[#027A48] w-6/12 text-white"
            >
              Verify
            </button>
          </div>
        </Modal> */}
        {isLoading && <Loader />}
        {!isLoading && data && (
          <section className="mt-10 flex gap-4">
            <section className="w-[30%]">
              <ProfileCard data={data} />
              <Options data={data?.data} />
            </section>
            <section className="w-[70%]">
              <Card width="12" style={{ marginBottom: "10px" }}>
                <div className="flex justify-between items-center">
                  <h1 className="text-xl W2STBold text-gray700 mb-3">
                    {data?.data?.firstName + " " + data?.data?.lastName}{" "}
                  </h1>
                  <span className="text-primary600 flex gap-3 items-center cursor-pointer">
                    <AiOutlineEdit /> Edit
                  </span>
                </div>
                <div className="mt-2">
                  <div className="flex gap-3 items-center">
                    <FiBriefcase style={{ color: "text-gray700" }} />{" "}
                    <span className="text-gray500 text-sm">
                      {data && data?.data?.professionalTitle}
                    </span>
                  </div>
                </div>
                <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                  <LuAward />
                  <span className="text-gray500">15 sessions (5 reviews)</span>
                </div>
                <div className="mt-3">
                  <p className="font-medium text-gray700">Available for:</p>
                  <div className="mt-3 flex gap-3 text-sm">
                    <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
                      <HiOutlineLocationMarker />
                      <span>Physical Tutoring</span>
                    </span>
                    <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
                      <PiTelevisionSimpleBold /> <span>Virtual Tutoring</span>
                    </span>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="text-gray700 text-sm font-medium">
                    Hourly rate (Non binding)
                  </p>
                  <h3 className="font-bold text-md text-gray900">
                    ${data && data?.data?.hourlyRate}/hr
                  </h3>
                </div>
              </Card>
              {/* <Card width="12">
              <div className="flex justify-between items-center">
                <h1 className="text-xl W2STBold text-gray700 mb-3">About</h1>
                <span className="text-primary600 flex gap-3 items-center cursor-pointer">
                  <AiOutlineEdit /> Edit
                </span>
              </div>
              <p className="text-gray500 my-3">
                Working as a tutor at Kumon Centers and teaching students
                privatelt for over 13 years. I have learned to adapt to a
                student's learning to not only help you understand hoe to do
                your homework but to work independently with confidence after
                your lesson. I have recieved numerous positive comments and
                feedback from students of all ages at different stages of their
                career as a student.
              </p>
            </Card> */}
              {data?.data?.education && (
                <Card width="12" style={{ marginTop: "10px" }}>
                  <div className="flex justify-between items-center">
                    <h1 className="text-xl W2STBold text-gray700 mb-3">
                      Education
                    </h1>
                  </div>
                  {data &&
                  data?.data?.education &&
                  data?.data?.education.length > 0 ? (
                    data?.data?.education.map(
                      ({ college, degree, enrolled, graduated, _id }: any) => (
                        <div key={_id}>
                          <EducationCard
                            degree={degree}
                            college={college}
                            enrolled={enrolled}
                            graduated={graduated}
                          />
                        </div>
                      )
                    )
                  ) : (
                    <Empty />
                  )}
                </Card>
              )}
              <Card width="12" style={{ marginTop: "10px" }}>
                <div className="flex justify-between items-center">
                  <h1 className="text-xl W2STBold text-gray700 mb-3">
                    Work Experience
                  </h1>
                </div>
                <div>
                  {data &&
                  data?.data?.workExperience &&
                  data?.data?.workExperience.length > 0 ? (
                    data?.data?.workExperience.map(
                      ({ title, company, startDate, endDate, _id }: any) => (
                        <div key={_id}>
                          <ExperienceCard
                            title={title}
                            company={company}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </div>
                      )
                    )
                  ) : (
                    <Empty />
                  )}
                </div>
              </Card>
            </section>
          </section>
        )}
      </section>
    </AdminDashboardLayout>
  );
};

export default TutorDetails;
