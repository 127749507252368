export const getToken = (): string | null => {
  try {
    const actualToken = localStorage.getItem("COTRACKR_USER_TOKEN");
    if (actualToken) {
      return JSON.parse(actualToken);
    }
    return null;
  } catch (error) {
    console.error("Failed to parse token:", error);
    return null;
  }
};
