import React from "react";
import TutorDashboardLayout from "../../../layout/TutorDashboardLayout";
import { useGetTutorQuery } from "../../../hooks/api/Tutors/Auth/useGetTutorQuery";
import Loader from "../../../components/Loader";
import Card from "../../../components/Card";
import ProfileCard from "../../../components/Tutor/ProfileCard";
import Options from "../../Admin/Tutors/components/Options";
import TutorProfilePreviewCard from "./components/TutorProfilePreviewCard";
import { Empty } from "antd";
import EducationCard from "../../Admin/Tutors/components/EducationCard";
import ExperienceCard from "../../Admin/Tutors/components/ExperienceCard";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { LuAward } from "react-icons/lu";
import { FiBriefcase } from "react-icons/fi";
import { AiOutlineEdit } from "react-icons/ai";
// import Layout from '../../components/Layout';
// import Card from '../../components/primary/Card';
// import PayoutCard from './PayoutCard';
// import AvailabilityCard from './AvailabilityCard';
// import BlogCard from './BlogCard';
// import ProfileCard from '../../components/primary/ProfileCard';
// import SessionCard from './SessionCard';
// import ProfileCompletness from './ProfileCompletness';
// import { useAppContext } from '../../hooks/useAppContext';
// import { useAppContext } from '../../hooks/useAppContext';
// import { useGetUserQuery } from '../../hooks/api/useGetUserQuery';
// import Cookies from 'js-cookie';
// import axios from 'axios';

const TutorProfile = () => {
  const { data, isLoading } = useGetTutorQuery();
  // const image = require('../../../assets/images/avatar.png');

  // const data = JSON.parse(localStorage.getItem('@usertoken'));
  // const userData = JSON.parse(localStorage.getItem('@user'));
  // console.log('---data--- usertoken ==>', data);
  // console.log('---data--- userData ==>', userData?._id);
  // const userData = localStorage.getItem('user');
  // const parsedData = JSON.parse(userData);

  // console.log('userdata', parsedData);

  // const check = Cookies.get('wst');
  // console.log('---OVERVIEW---', check);

  // console.log('userDatauserDatauserData', data._id);
  // console.log('ID:', parsedData?._id);

  // const { data: user } = useGetUserQuery(parsedData._id);
  // console.log('USER:', user);

  // useEffect(() => {
  //   console.log('token: ', Cookies.get('w2st-c'));
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: 'https://w2st-server.onrender.com/api/v1/tutors/655446af9d1918908f089b07',
  //     headers: {
  //       Cookie: `w2st-c=${Cookies.get('w2st-c')}`,
  //     },
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log('response: ', JSON.stringify(response.data));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  //   const { tutor } = useAppContext();

  return (
    <TutorDashboardLayout>
      {isLoading && <Loader />}
      {!isLoading && data && (
        <section className="mt-10 flex gap-4">
          <section className="w-[30%]">
            <TutorProfilePreviewCard data={data} />
            <Options data={data?.data} />
          </section>
          <section className="w-[70%]">
            <Card width="12" style={{ marginBottom: "10px" }}>
              <div className="flex justify-between items-center">
                <h1 className="text-xl W2STBold text-gray700 mb-3">
                  {data?.data?.firstName + " " + data?.data?.lastName}{" "}
                </h1>
                <span className="text-primary600 flex gap-3 items-center cursor-pointer">
                  <AiOutlineEdit /> Edit
                </span>
              </div>
              <div className="mt-2">
                <div className="flex gap-3 items-center">
                  <FiBriefcase style={{ color: "text-gray700" }} />{" "}
                  <span className="text-gray500 text-sm">
                    {data && data?.data?.professionalTitle}
                  </span>
                </div>
              </div>
              <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                <LuAward />
                <span className="text-gray500">15 sessions (5 reviews)</span>
              </div>
              <div className="mt-3">
                <p className="font-medium text-gray700">Available for:</p>
                <div className="mt-3 flex gap-3 text-sm">
                  <span className="rounded-full px-3 py-2 text-indigo700 bg-indigo50 text-sm flex items-center gap-2">
                    <HiOutlineLocationMarker />
                    <span>Physical Tutoring</span>
                  </span>
                  <span className="rounded-full px-3 py-2 text-pink500 bg-pink25 text-sm flex items-center gap-2">
                    <PiTelevisionSimpleBold /> <span>Virtual Tutoring</span>
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <p className="text-gray700 text-sm font-medium">
                  Hourly rate (Non binding)
                </p>
                <h3 className="font-bold text-md text-gray900">
                  ${data && data?.data?.hourlyRate}/hr
                </h3>
              </div>
            </Card>
            {data?.data?.education && (
              <Card width="12" style={{ marginTop: "10px" }}>
                <div className="flex justify-between items-center">
                  <h1 className="text-xl W2STBold text-gray700 mb-3">
                    Education
                  </h1>
                </div>
                {data &&
                data?.data?.education &&
                data?.data?.education.length > 0 ? (
                  data?.data?.education.map(
                    ({ college, degree, enrolled, graduated, _id }: any) => (
                      <div key={_id}>
                        <EducationCard
                          degree={degree}
                          college={college}
                          enrolled={enrolled}
                          graduated={graduated}
                        />
                      </div>
                    )
                  )
                ) : (
                  <Empty />
                )}
              </Card>
            )}
            <Card width="12" style={{ marginTop: "10px" }}>
              <div className="flex justify-between items-center">
                <h1 className="text-xl W2STBold text-gray700 mb-3">
                  Work Experience
                </h1>
              </div>
              <div>
                {data &&
                data?.data?.workExperience &&
                data?.data?.workExperience.length > 0 ? (
                  data?.data?.workExperience.map(
                    ({ title, company, startDate, endDate, _id }: any) => (
                      <div key={_id}>
                        <ExperienceCard
                          title={title}
                          company={company}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    )
                  )
                ) : (
                  <Empty />
                )}
              </div>
            </Card>
          </section>
        </section>
      )}

      {/* <div className="md:flex block md:gap-2">
        <section className="md:w-8/12 w-full">
          <section className="p-4 bg-white rounded-lg my-4">
            <div className="pb-5 border-b-[1px] border-gray200">
              <h3 className="text-2xl W2STBold">
                Welcome
                {tutor && tutor?.completed !== false
                  ? ''
                  : `, ${tutor?.firstname}`}
              </h3>
              <p>
                You have{' '}
                <span className="W2STBold underline text-primary700">
                  2 upcoming sessions
                </span>
              </p>
            </div>
            <ProfileCompletness />

            <h3 className="text-gray900 mb-3 mt-6 text-lg">Profile overview</h3>
            <div className="flex gap-2">
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Total session time</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  1,210 Mins
                </p>
              </div>
              <div className="p-4 bg-gray25 rounded-lg w-full border-[1px] border-gray100">
                <h3 className="text-gray500">Average rating</h3>
                <p className="W2STBold md:text-3xl text-lg text-gray900">
                  4.5/5
                </p>
              </div>
            </div>
          </section>
          <Card width="12">
            <section className="flex justify-between items-center ">
              <h3 className="text-gray900 text-lg">Recent Sessions</h3>
              <span className="text-primary600">View all</span>
            </section>
            <section className="mt-5">
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
              <SessionCard
                image={image}
                name="Noah Pierre"
                subject="Elementary English"
                sessionType="Physical"
                duration="120"
              />
            </section>
          </Card>
        </section>
        <section className="md:w-4/12 w-full">
          <section className="md:p-4 w-full md:mt-0 mt-4">
            <ProfileCard />
            <div className="p-4 bg-white rounded-lg">
              <h3 className="W2STBold font-2xl text-gray900">Payout</h3>
              <PayoutCard amount="1,210" date="July 23, 2023" status="paid" />
              <PayoutCard amount="210" date="July 31, 2023" status="pending" />
            </div>
            <AvailabilityCard />
            <BlogCard />
          </section>
        </section>
      </div> */}
    </TutorDashboardLayout>
  );
};

export default TutorProfile;
