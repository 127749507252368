import React, { ReactNode, useEffect, useState } from "react";
import { GrNotification } from "react-icons/gr";
import { BiBriefcaseAlt } from "react-icons/bi";
import { PiCalendarBlankBold } from "react-icons/pi";
import { FaRegClock } from "react-icons/fa";
import { CgSupport } from "react-icons/cg";
import { FiLayers, FiSettings } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import LayoutSideLink from "../components/LayoutSideLink";
import withTutorAuth from "../hoc/withTutorAuth";
import WelcomeLoader from "../components/WelcomeLoader";
import { message, Modal } from "antd";
import { useGetTutorQuery } from "../hooks/api/Tutors/Auth/useGetTutorQuery";
import { useSendVerifyOTPMutation } from "../hooks/api/Tutors/Auth/useSendVerifyOTPMutation";
import { useVerifyOTPMutation } from "../hooks/api/Tutors/Auth/useVerifyOTPMutation";

const TutorDashboardLayout = ({ children }: { children: ReactNode }) => {
  const Logo = require("../assets/images/logo.png");
  const Avatar = require("../assets/images/avatar.png");
  const { data: tutor } = useGetTutorQuery();
  const navigate = useNavigate();

  const [otp, setOTP] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [level, setLevel] = useState(0);

  useEffect(() => {
    if (tutor?.data?.onBoardingProgress < 4) {
      navigate("/tutor/onboarding", { replace: true });
    }
  }, [tutor, navigate]);

  const { mutateAsync, isLoading } = useSendVerifyOTPMutation();
  const { mutateAsync: verifyOTP, isLoading: isVerifying } =
    useVerifyOTPMutation(otp);

  const handleInitialize = () => {
    mutateAsync(); // Pass only `data` if that's the expected argument
    setLevel(1); // Execute on success
    // }  (error) {
    //   console.error("Error occurred:", error); // Handle errors if needed
    // }
  };

  const handleVerify = () => {
    verifyOTP();
  };

  // useEffect(() => {
  //   if (
  //     tutor?.data?.isEmailVerified === false &&
  //     tutor?.data?.onBoardingProgress >= 4
  //   ) {
  //     setVisibility(true);
  //   }
  // }, []);

  return (
    <div className="flex flex-col h-[100vh]">
      <WelcomeLoader />
      {/* <Modal
        width="400px"
        footer={null}
        closeIcon={false}
        open={
          tutor?.data?.isEmailVerified === false &&
          tutor?.data?.onBoardingProgress >= 4
        }
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <h3 className="text-xl font-bold">Email Verification</h3>
        <p>
          Proceed to verify your email address. An OTP been sent to your email
          for verfication purposes.
        </p>
        {level === 0 ? (
          <div className="block">
            <button
              onClick={() => handleInitialize()}
              className={`block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md ${
                !isLoading ? "bg-primary500" : "bg-primary200"
              }`}
            >
              Proceed
            </button>
          </div>
        ) : (
          <div>
            <label htmlFor="otp" className="font-gray mt-3 block">
              Email OTP
            </label>
            <input
              name="otp"
              type="number"
              placeholder=""
              value={otp}
              className="px-6 py-4 my-3 rounded-md w-full cursor-pointer bg-slate-100"
              onChange={(e) => setOTP(e.target.value)}
            />
            <button
              onClick={() => handleVerify()}
              className={`block text-center mt-4 py-5 w-full mb-4 rounded-md text-white text-md ${
                !isLoading ? "bg-primary500" : "bg-primary200"
              }`}
            >
              Submit
            </button>
          </div>
        )}
      </Modal> */}
      <div className="flex-none">
        <div className="flex justify-between items-center border-b-[1px] bg-bottom-[#E4E7EC] p-4 bg-white">
          <Link to="/">
            <img src={Logo} alt="W2ST Logo" className="logo h-[40px]" />
          </Link>
          <section className="flex gap-4 items-center">
            <GrNotification style={{ fontSize: "20px" }} />
            <img src={Avatar} alt="" className="logo h-[40px]" />
            <button
              className="bg-red-700 text-white p-3"
              onClick={() => {
                localStorage.removeItem("W2ST_TUTOR_TOKEN");
                localStorage.removeItem("hasSeenWelcomeLoader");
                message.success("Logout successful");
                navigate("/tutor/signin");
              }}
            >
              Logout
            </button>
          </section>
        </div>
      </div>
      <div className="grow">
        <div className="flex gap-[20px] bg-[#F9FAFB]">
          <div className="w-2/12 border-r-[1px] bg-white p-4 md:block hidden">
            <section className="">
              <nav>
                <ul>
                  <LayoutSideLink to="/tutor/overview" title="Overview">
                    <FiLayers style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/tutor/bookings" title="Bookings">
                    <FaRegClock style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/tutor/availability" title="Availability">
                    <PiCalendarBlankBold style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/tutor/profile" title="Profile">
                    <BiBriefcaseAlt style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                </ul>
              </nav>
              <span className="absolute bottom-0 left-0 border-t-[1px] border-[#E4E7EC] w-2/12 px-4 -ml-[3px]">
                <ul>
                  <LayoutSideLink to="/tutor/support" title="Support">
                    <CgSupport style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                  <LayoutSideLink to="/tutor/settings" title="Setting">
                    <FiSettings style={{ fontSize: "20px" }} />
                  </LayoutSideLink>
                </ul>
              </span>
            </section>
          </div>
          {/* <div className="w-10/12 p-4 bg-[#F9FAFB]"> */}
          <div className="md:w-10/12 w-full h-[90vh] md:pr-4 px-4 overflow-y-scroll ">
            <div style={{ overflowY: "scroll" }}>{children}</div>
          </div>
        </div>
      </div>
      <div className="md:hidden block flex-none bg-black h-[70px]">
        Lorem ipsum dolor sit amet consectetur adipisicing
      </div>
    </div>
  );
};

export default withTutorAuth(TutorDashboardLayout);
