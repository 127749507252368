import React from "react";
import Card from "../../../../components/Card";

const Options = (data: any) => {
  return (
    <div>
      {data?.data?.areaOfExpertise && (
        <Card width="12" style={{ marginTop: "10px" }}>
          <div className="flex justify-between items-center">
            <h1 className="text-xl W2STBold text-gray700 mb-3">
              Subject expertise
            </h1>
          </div>
          <div>
            <span className="blob font-bold capitalize">
              {data?.data?.areaOfExpertise[0]}
            </span>
          </div>
        </Card>
      )}

      {data?.data?.preferredLanguage && (
        <Card width="12" style={{ marginTop: "10px" }}>
          <div className="flex justify-between items-center">
            <h1 className="text-xl W2STMedium text-gray700 mb-3">Language</h1>
          </div>
          <div>
            <span className="blob">
              <span className="font-bold">{data?.data?.preferredLanguage}</span>
            </span>
          </div>
        </Card>
      )}
    </div>
  );
};

export default Options;
