import React, { useState } from "react";
import StudentDashboard from "../../../layout/StudentDashboard";
import { useGetTutorsQuery } from "../../../hooks/api/Students/Tutors/useGetTutorsQuery";
import { LuAward } from "react-icons/lu";
import { FiBriefcase } from "react-icons/fi";
import { Drawer } from "antd";
import AvailabilityBadge from "./Components/AvailabilityBadge";
import TutorBookingForm from "./Components/TutorBookingForm";
import { GrClose } from "react-icons/gr";
import Loader from "../../../components/Loader";
import { useGetTutorQuery } from "../../../hooks/api/Students/Tutors/useGetTutorQuery";

const StudentTutors = () => {
  const { isLoading, data } = useGetTutorsQuery({});
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);

  const { data: tutorsData, isLoading: tutorsLoading } = useGetTutorQuery(id);

  const tutorsList = data?.data?.docs;

  if (isLoading && !data) {
    return (
      <StudentDashboard>
        <Loader />
      </StudentDashboard>
    );
  }

  return (
    <StudentDashboard>
      <div className="flex gap-5">
        <div className="w-[300px]  rounded-md shadow-sm bg-white">
          <div className="px-[18px] py-[20px] border-b-[1px] border-gray-200 flex items-center justify-between">
            <span className="W2STMedium">Filter Options</span>
            <button className="p-2 bg-white rounded-full shadow-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
          <div className="p-[20px]">
            <label htmlFor="search"></label>
            <input
              name="search"
              placeholder="Search"
              type="text"
              className="w-full p-[10px] rounded-[10px] border-[1px] border-gray300"
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="grid grid-cols-3 gap-3">
            {tutorsList &&
              tutorsList.map(
                ({
                  firstName,
                  lastName,
                  professionalTitle,
                  availabilityType,
                  id,
                  hourlyRate,
                }: any) => (
                  <div
                    key={id}
                    className="bg-white rounded-md shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => {
                      setId(id);
                      setVisible(true);
                    }}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc22FgIapuN5SmPjiN6uI3Vfj1uNO1ShdSg&s"
                      alt="tutor avatar"
                      className="h-[218px] rounded-t-lg w-full"
                    />
                    <div className="p-[16px]">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {firstName + " " + lastName}
                      </h3>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <FiBriefcase style={{ color: "text-gray700" }} />
                          <span className="text-gray500 text-sm">
                            {professionalTitle}
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 bg-warning50 rounded-md flex gap-2 items-center p-2 text-sm">
                        <LuAward />
                        <span className="text-gray500">
                          15 sessions (5 reviews)
                        </span>
                      </div>
                      <AvailabilityBadge availabilityType={availabilityType} />
                      <div className="flex justify-between mt-[16px]">
                        <div>
                          <p className="W2STMedium text-gray900">
                            ${hourlyRate}/hr
                          </p>
                          <h3 className="text-gray500">Hourly rate</h3>
                        </div>
                        <button className="text-white px-[27px] py-[10px] rounded-md bg-primary900 W2STMedium">
                          Book Tutor
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            <Drawer
              title={
                <div className="flex justify-between items-center">
                  <h3>Tutor Details</h3>
                  <GrClose
                    onClick={() => setVisible(false)}
                    className="cursor-pointer"
                  />
                </div>
              }
              open={visible}
              closable={false}
              maskClosable={false}
            >
              {tutorsLoading && <Loader />}
              {!tutorsLoading && tutorsData && (
                <div>
                  <div className="flex gap-2 mb-3">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc22FgIapuN5SmPjiN6uI3Vfj1uNO1ShdSg&s"
                      className="h-[60px] rounded-full w-[60px]"
                      alt="avatar"
                    />
                    <div className="flex-1">
                      <h3 className="W2STMedium mb-[5px] text-gray900 md:text-[18px] text-[14px]">
                        {tutorsData?.data?.firstName +
                          " " +
                          tutorsData?.data?.lastName}
                      </h3>
                      <div className="mt-2">
                        <div className="flex gap-3 items-center">
                          <FiBriefcase style={{ color: "text-gray700" }} />
                          <span className="text-gray500">
                            {tutorsData?.data?.professionalTitle}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <TutorBookingForm id={id} setVisible={setVisible} />
                  </div>
                </div>
              )}
            </Drawer>
          </div>
        </div>
      </div>
    </StudentDashboard>
  );
};

export default StudentTutors;
